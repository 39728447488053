/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:bc27e5b6-4917-4ea6-b892-e3ab28068c9c",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_L8jdIedj8",
    "aws_user_pools_web_client_id": "7ogukv5n6fepjk1d77u3dl1a41",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://6wvvs2nol5gdvpu43j2hheng3a.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "photosharing131825-prod",
    "aws_user_files_s3_bucket_region": "eu-west-1",
    "aws_content_delivery_bucket": "photosharing-prod-131825-hosting",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "https://d2elv1a4mqciwj.cloudfront.net"
};


export default awsmobile;
